import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { AssetsTable } from "@/components/Permissions/UsersAssets/AssetsTable.component";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import PropTypes from "prop-types";
import React, { useImperativeHandle } from "react";

const preferencesType = preferencesTypes.USER_ASSETS_ACCOUNTS_TABLE;

export const AccountsTable = React.memo(
    React.forwardRef((props, ref) => {
        const { permissionsAssetsService } = useDic();

        const [tableProps, { reload }] = useFetchPage(
            (page, size, sort = "name,asc", filter) =>
                permissionsAssetsService.fetchProjects({
                    page,
                    size,
                    sort,
                    filter,
                    userIds: props.userIds,
                }),
            [],
        );

        useImperativeHandle(ref, () => ({
            reload,
        }));

        return (
            <AssetsTable
                preferencesType={preferencesType}
                assetType="project"
                datasetSlicing="server"
                {...tableProps}
                {...props}
            />
        );
    }),
);

AccountsTable.propTypes = {
    userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};
