import { ActivityLogPage } from "@/components/ActivityLog/ActivityLog.page";
import AdminReportsList from "@/components/AdminReports/AdminReportsList";
import { AlertRulesAdminPage } from "@/components/AlertRules/AlertRulesPage.admin.page";
import { NewAlertRuleAdminPage } from "@/components/AlertRules/NewAlertRule.admin.page";
import { EnableDeeplinksPage } from "@/components/EnableDeeplinks/EnableDeeplinks.page";
import { EngSupportUsersPage } from "@/components/EngSupportUsers/EngSupportUsers.page";
import { AdminFeatureFlagsPage } from "@/components/FeatureFlags/AdminFeatureFlagsPage";
import { ImInstancesLimitPage } from "@/components/ImInstancesLimit/ImInstancesLimit.page";
import { AdminIaaSPage } from "@/components/IntegrationAsAService/AdminIaaS.page";
import { EditIaaSPage } from "@/components/IntegrationAsAService/EditIaaS.page";
import { NewIaaSPage } from "@/components/IntegrationAsAService/NewIaaS.page";
import { IntegrationManagerVersionsPage } from "@/components/IntegrationManagerVersions/IntegrationManagerVersionsPage.component";
import { PartitionLimitPage } from "@/components/PartitionLimit/PartitionLimit.page";
import { AtlassianGroupsAdminPage } from "@/components/Permissions/AtlassianGroups.admin.page";
import { BusinessRolesAdminPage } from "@/components/Permissions/BusinessRoles.admin.page";
import { SubAssetsAdminPage } from "@/components/Permissions/SubAssets.admin.page";
import { UserGroupsAdminPage } from "@/components/Permissions/UserGroups.admin.page";
import { UsersAdminPage } from "@/components/Permissions/Users.admin.page";
import { UsersAccountsAdminPage } from "@/components/Permissions/UsersAccounts.admin.page";
import { UsersAssetsAdminPage } from "@/components/Permissions/UsersAssets.admin.page";
import PmFeatureFlags from "@/components/PmFeatureFlags/PmFeatureFlags";
import { SingleSignOnAdminPage } from "@/components/SingleSignOn/SingleSignOnAdminPage";
import { createLocation } from "@/modules/router";
import {
    ADMIN_ACTIVITY_LOG_READ,
    ASSET_ROLES_MANAGEMENT_PERMISSIONS,
    ATLASSIAN_GROUPS_MANAGEMENT_PERMISSIONS,
    BUSINESS_ROLES_MANAGEMENT_PERMISSIONS,
    ENABLE_DEEPLINKS_MANAGEMENT_PERMISSION,
    FEATURE_FLAGS_EDIT,
    FEATURE_FLAGS_READ,
    GLOBAL_ALERTS_PERMISSIONS,
    GLOBAL_ALERT_EDIT_PERMISSION,
    GROUPS_MANAGEMENT_PERMISSIONS,
    GROUP_MANAGEMENT_EDIT_PERMISSION,
    PERMISSION_POWER_USER_PERMISSION,
    USERS_MANAGEMENT_PERMISSIONS,
    USER_MANAGEMENT_EDIT_PERMISSION,
    VERSIONS_MANAGEMENT_PERMISSION,
} from "@/security/permission.utils";
import { administrationAppLocation } from "../../locations";
import { AtlassianGroupsPage } from "../../views/Settings/AtlassianGroups/AtlassianGroupsPage";
import { GroupListContainer } from "../../views/Settings/Groups/GroupListContainer";
import { UsersListContainer } from "../../views/Settings/Users/UsersListContainer";
import { AssetRolesPage } from "./pages/AssetRolesPage/AssetRolesPage";
import { BusinessRolesPage } from "./pages/BusinessRolesPage/BusinessRolesPage";
import { GlobalAlertsPage } from "./pages/GlobalAlertsPage/GlobalAlertsPage.component";
import { LoggersPage } from "./pages/LoggersPage/LoggersPage.component";
import { PartitionUpgradesPage } from "./pages/PartitionUpgradesPages/PartitionUpgradesPage.component";
import { ReleaseVersionsPage } from "./pages/ReleaseVersionsPage/ReleaseVersionsPage.component";

const globalAlertsLocation = createLocation(
    administrationAppLocation,
    "globalAlerts",
    "/global-alerts",
    {
        component: GlobalAlertsPage,
        permission: GLOBAL_ALERTS_PERMISSIONS,
    },
);

const globalAlertsRulesLocation = createLocation(
    globalAlertsLocation,
    "rules",
    "/rules",
    {
        component: AlertRulesAdminPage,
        permission: GLOBAL_ALERTS_PERMISSIONS,
    },
);

const newGlobalAlertRuleLocation = createLocation(
    globalAlertsRulesLocation,
    "new",
    "/new",
    {
        component: NewAlertRuleAdminPage,
        permission: GLOBAL_ALERT_EDIT_PERMISSION,
    },
);

const globalAlertRulesEditLocation = createLocation(
    globalAlertsRulesLocation,
    "edit",
    "/:alertRuleId/edit",
    {
        component: NewAlertRuleAdminPage,
        permission: GLOBAL_ALERT_EDIT_PERMISSION,
    },
);

const logsLocation = createLocation(
    administrationAppLocation,
    "logs",
    "/logs",
    {
        forwardTo: "administrationApp.logs.activityLog",
        permission: [
            ADMIN_ACTIVITY_LOG_READ,
            PERMISSION_POWER_USER_PERMISSION,
            GROUP_MANAGEMENT_EDIT_PERMISSION,
            USER_MANAGEMENT_EDIT_PERMISSION,
        ],
    },
);

const activityLogLocation = createLocation(
    logsLocation,
    "activityLog",
    "/activity-log",
    {
        component: ActivityLogPage,
        permission: [
            ADMIN_ACTIVITY_LOG_READ,
            PERMISSION_POWER_USER_PERMISSION,
            GROUP_MANAGEMENT_EDIT_PERMISSION,
            USER_MANAGEMENT_EDIT_PERMISSION,
        ],
    },
);

const loggersLocation = createLocation(
    administrationAppLocation,
    "loggers",
    "/loggers",
    {
        component: LoggersPage,
        permission: PERMISSION_POWER_USER_PERMISSION,
    },
);

const constantsLocation = createLocation(
    administrationAppLocation,
    "constants",
    "/constants",
    {
        forwardTo: "administrationApp.constants.releaseVersions",
        permission: [
            VERSIONS_MANAGEMENT_PERMISSION,
            PERMISSION_POWER_USER_PERMISSION,
        ],
    },
);

const releaseVersionsLocation = createLocation(
    constantsLocation,
    "releaseVersions",
    "/release-versions",
    {
        component: ReleaseVersionsPage,
        permission: [
            VERSIONS_MANAGEMENT_PERMISSION,
            PERMISSION_POWER_USER_PERMISSION,
        ],
    },
);

const partitionUpgradesLocation = createLocation(
    constantsLocation,
    "partitionUpgrades",
    "/partition-upgrades",
    {
        component: PartitionUpgradesPage,
        permission: [
            VERSIONS_MANAGEMENT_PERMISSION,
            PERMISSION_POWER_USER_PERMISSION,
        ],
    },
);

const imInstancesLimitLocation = createLocation(
    constantsLocation,
    "imInstancesLimit",
    "/im-instances-limit",
    {
        component: ImInstancesLimitPage,
        permission: ["asset.limit.manager"],
    },
);

const partitionLimitLocation = createLocation(
    constantsLocation,
    "partitionLimit",
    "/partition-limit",
    {
        component: PartitionLimitPage,
        permission: ["asset.limit.manager"],
    },
);

const engSupportUsersLocation = createLocation(
    constantsLocation,
    "engSupportUsers",
    "/eng-support-users",
    {
        component: EngSupportUsersPage,
        permission: [PERMISSION_POWER_USER_PERMISSION],
    },
);

const enableDeeplinksLocation = createLocation(
    constantsLocation,
    "enableDeeplinks",
    "/enable-deeplinks",
    {
        component: EnableDeeplinksPage,
        permission: [ENABLE_DEEPLINKS_MANAGEMENT_PERMISSION],
    },
);

const integrationManagerVersionsLocation = createLocation(
    administrationAppLocation,
    "integrationManagerVersions",
    "/integration-manager-versions",
    {
        component: IntegrationManagerVersionsPage,
        permission: [
            PERMISSION_POWER_USER_PERMISSION,
            VERSIONS_MANAGEMENT_PERMISSION,
        ],
    },
);

const featureFlagsLocation = createLocation(
    administrationAppLocation,
    "featureFlags",
    "/feature-flags",
    {
        component: AdminFeatureFlagsPage,
        permission: [FEATURE_FLAGS_READ, FEATURE_FLAGS_EDIT],
    },
);

const IaaSLocation = createLocation(
    administrationAppLocation,
    "IaaS",
    "/integration-as-a-service",
    {
        component: AdminIaaSPage,
        permission: [PERMISSION_POWER_USER_PERMISSION],
    },
);

const newIaaSOverrideLocation = createLocation(
    IaaSLocation,
    "new-override",
    "/new-override",
    {
        component: NewIaaSPage,
        permission: [PERMISSION_POWER_USER_PERMISSION],
    },
);

const editIaaSOverrideLocation = createLocation(
    IaaSLocation,
    "edit-override",
    "/edit-override/:id",
    {
        component: EditIaaSPage,
        permission: [PERMISSION_POWER_USER_PERMISSION],
    },
);

const permissionsAdminLocation = createLocation(
    administrationAppLocation,
    "permissions",
    "/permissions",
    {
        permission: [
            ...USERS_MANAGEMENT_PERMISSIONS,
            ...GROUPS_MANAGEMENT_PERMISSIONS,
            ...ASSET_ROLES_MANAGEMENT_PERMISSIONS,
            ...BUSINESS_ROLES_MANAGEMENT_PERMISSIONS,
        ],
        forwardTo: "administrationApp.permissions.users", // TODO: conditionally redirect to groupsLocation
    },
);

const permissionsAdminUsersLocation = createLocation(
    permissionsAdminLocation,
    "users",
    "/users",
    {
        component: UsersAdminPage,
        permission: USERS_MANAGEMENT_PERMISSIONS,
    },
);

const permissionsAdminUsersAccountsLocation = createLocation(
    permissionsAdminUsersLocation,
    "users-accounts",
    "/:userIds/accounts",
    {
        component: UsersAccountsAdminPage,
        permission: USERS_MANAGEMENT_PERMISSIONS,
    },
);

const permissionsAdminUsersAssetsLocation = createLocation(
    permissionsAdminUsersAccountsLocation,
    "users-assets",
    "/:accountId/assets",
    {
        component: UsersAssetsAdminPage,
        permission: USERS_MANAGEMENT_PERMISSIONS,
    },
);

const permissionsAdminSubAssetsLocation = createLocation(
    permissionsAdminUsersAssetsLocation,
    "sub-assets",
    "/:assetType/:assetId/sub-assets",
    {
        component: SubAssetsAdminPage,
        permission: USERS_MANAGEMENT_PERMISSIONS,
    },
);

const permissionsAdminUserGroupsLocation = createLocation(
    permissionsAdminLocation,
    "groups",
    "/groups",
    {
        component: UserGroupsAdminPage,
        permission: GROUPS_MANAGEMENT_PERMISSIONS,
    },
);

const permissionsAdminAssetGroupsLocation = createLocation(
    permissionsAdminLocation,
    "assetRoles",
    "/asset-roles",
    {
        component: AssetRolesPage,
        permission: [PERMISSION_POWER_USER_PERMISSION],
    },
);

const permissionsAdminBusinessRolesLocation = createLocation(
    permissionsAdminLocation,
    "businessRoles",
    "/business-roles",
    {
        component: BusinessRolesAdminPage,
        permission: BUSINESS_ROLES_MANAGEMENT_PERMISSIONS,
    },
);

const permissionsAdminAtlassianGroupsLocation = createLocation(
    permissionsAdminLocation,
    "atlassianGroups",
    "/atlassian-groups",
    {
        component: AtlassianGroupsAdminPage,
        permission: ATLASSIAN_GROUPS_MANAGEMENT_PERMISSIONS,
    },
);

const rightsLocation = createLocation(
    administrationAppLocation,
    "rights",
    "/rights",
    {
        permission: [
            ...USERS_MANAGEMENT_PERMISSIONS,
            ...GROUPS_MANAGEMENT_PERMISSIONS,
            ...ASSET_ROLES_MANAGEMENT_PERMISSIONS,
            ...BUSINESS_ROLES_MANAGEMENT_PERMISSIONS,
        ],
        forwardTo: "administrationApp.rights.users", // TODO: conditionally redirect to groupsLocation
    },
);

const usersLocation = createLocation(rightsLocation, "users", "/users", {
    component: UsersListContainer,
    permission: USERS_MANAGEMENT_PERMISSIONS,
});

const groupsLocation = createLocation(rightsLocation, "groups", "/groups", {
    component: GroupListContainer,
    permission: GROUPS_MANAGEMENT_PERMISSIONS,
});

const assetRightsLocation = createLocation(
    rightsLocation,
    "assetRoles",
    "/asset-roles",
    {
        component: AssetRolesPage,
        permission: ASSET_ROLES_MANAGEMENT_PERMISSIONS,
    },
);

const businessRolesLocation = createLocation(
    rightsLocation,
    "businessRoles",
    "/business-roles",
    {
        component: BusinessRolesPage,
        permission: BUSINESS_ROLES_MANAGEMENT_PERMISSIONS,
    },
);

const atlassianGroupsLocation = createLocation(
    rightsLocation,
    "atlassianGroups",
    "/atlassian-groups",
    {
        component: AtlassianGroupsPage,
        permission: ATLASSIAN_GROUPS_MANAGEMENT_PERMISSIONS,
    },
);

const ssoAdminLocation = createLocation(
    administrationAppLocation,
    "sso",
    "/sso",
    {
        component: SingleSignOnAdminPage,
        permission: ["permission.power.user"],
    },
);

const adminReportsLocation = createLocation(
    administrationAppLocation,
    "adminReports",
    "/admin-reports",
    {
        component: AdminReportsList,
        permission: ["permission.power.user"],
    },
);

// Not set in PM but AD
const AD_PM_FF_PERMISSION = "featureFlagAdmin";

const pmFeatureFlagsLocation = createLocation(
    administrationAppLocation,
    "pmFeatureFlags",
    "/pm-feature-flags",
    {
        component: PmFeatureFlags,
        permission: [AD_PM_FF_PERMISSION],
    },
);

export const administrationAppLocationsMap = {
    globalAlertsLocation,
    globalAlertsRulesLocation,
    newGlobalAlertRuleLocation,
    globalAlertRulesEditLocation,
    logsLocation,
    activityLogLocation,
    loggersLocation,
    constantsLocation,
    releaseVersionsLocation,
    integrationManagerVersionsLocation,
    featureFlagsLocation,
    partitionUpgradesLocation,
    imInstancesLimitLocation,
    rightsLocation,
    assetRightsLocation,
    businessRolesLocation,
    usersLocation,
    groupsLocation,
    atlassianGroupsLocation,
    ssoAdminLocation,
    adminReportsLocation,
    pmFeatureFlagsLocation,
    partitionLimitLocation,
    engSupportUsersLocation,
    enableDeeplinksLocation,
    permissionsAdminLocation,
    permissionsAdminUsersLocation,
    permissionsAdminUsersAccountsLocation,
    permissionsAdminUsersAssetsLocation,
    permissionsAdminSubAssetsLocation,
    permissionsAdminUserGroupsLocation,
    permissionsAdminAssetGroupsLocation,
    permissionsAdminBusinessRolesLocation,
    permissionsAdminAtlassianGroupsLocation,
    IaaSLocation,
    newIaaSOverrideLocation,
    editIaaSOverrideLocation,
};
